import { NextPage } from 'next'
import { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import moment from 'moment'
moment.locale('ja')
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useDispatch, useSelector } from 'react-redux'
import { useSpaceSetRedirectUrl } from 'hooks/space/set_redirect_url'

import Layout from 'components/commons/layouts/Layout'
import SearchSection from 'components/domains/top/SearchSection'
import StoreSection from 'components/domains/top/StoreSection'
import MainBannerSection from 'components/domains/top/MainBannerSection'
import UsageSection from 'components/domains/top/UsageSection'
import FeatureSection from 'components/domains/top/FeatureSection'

import LocationDisplaySection from 'components/domains/top/LocationDisplaySection'
import CostSection from 'components/domains/top/CostSection'
import ReviewSection from 'components/domains/top/ReviewSection'
import OwnerSection from 'components/domains/top/OwnerSection'
import CountrySection from 'components/domains/top/CountrySection'

import { AuthContext } from 'providers/AuthProvider'

import { StoreState } from 'state/ducks'
import { fetchPrefecturesDataRequest } from 'state/ducks/prefecture/actions'

// 国情報
import { fetchCountriesRequest } from 'state/ducks/country/actions'

import { useCustomIntercom } from 'hooks/common/intercom'

import Meta from 'lib/meta'

import styles from '../styles/index.module.scss'

const Top: NextPage<{ locale: string }> = ({ locale }): JSX.Element => {
  const { isSigned } = useContext(AuthContext)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const prefectures = useSelector((state: StoreState) => state.prefectures)

  const countries = useSelector((state: StoreState) => state.countries)

  //Locationまでスクロール用のDomを生成
  const refTopLocation = useRef<HTMLDivElement>()
  const scrollLocationSection = useCallback(() => {
    refTopLocation.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [refTopLocation])

  //預かり場所
  const locations = [
    {
      name: t('TOP.LOCATION.TOKYO'),
      slug: 'tokyo/1',
      lat: 35.681236,
      lon: 139.767125,
      backgroundImage: 'url("/tokyo.webp") no-repeat',
    },
    {
      name: t('TOP.LOCATION.OSAKA'),
      slug: 'osaka/20',
      lat: 34.7334658,
      lon: 135.5002547,
      backgroundImage: 'url("/osaka.webp") no-repeat',
    },
    {
      name: t('TOP.LOCATION.KYOTO'),
      slug: 'kyoto/2',
      lat: 34.985849,
      lon: 135.7587667,
      backgroundImage: 'url("/kyoto.webp") no-repeat',
    },
    {
      name: t('TOP.LOCATION.FUKUOKA'),
      slug: 'fukuoka/79',
      lat: 33.5897275,
      lon: 130.4207274,
      backgroundImage: 'url("/fukuoka.webp") no-repeat',
    },
    {
      name: t('TOP.LOCATION.NAGOYA'),
      slug: 'aichi/3',
      lat: 35.170915,
      lon: 136.8815369,
      backgroundImage: 'url("/nagoya.webp") no-repeat',
    },
    {
      name: t('TOP.LOCATION.SAPPORO'),
      slug: 'hokkaido/14',
      lat: 43.0686606,
      lon: 141.3507552,
      backgroundImage: 'url("/sapporo.webp") no-repeat',
    },
  ]

  useEffect(() => {
    // ページ初回ロード時に都道府県APIにリクエスト
    dispatch(fetchPrefecturesDataRequest())

    // 国情報を取得
    dispatch(fetchCountriesRequest())
  }, [dispatch])

  useEffect(() => {
    const pos = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    )
    if (pos > 0) {
      document.querySelector('body').scrollTo(0, 0)
    }
  }, [])

  const { openIntercomMessage } = useCustomIntercom()

  useEffect(() => {
    openIntercomMessage()
    useSpaceSetRedirectUrl()
  }, [])

  return (
    <Layout>
      <Meta
        title={t('META.TITLE.TOP_PAGE')}
        description={t('META.DESCRIPTION.TOP_PAGE')}
        url={`/${locale === 'default' ? 'ja' : locale}`}
      />
      <div className={styles.wrap}>
        <SearchSection locale={locale} />
        {locale === 'ja' ? <MainBannerSection locale={locale} /> : <StoreSection />}
        {!isSigned && <UsageSection style={locale === 'ja' && styles.usageSection} />}
        {!isSigned && <FeatureSection countryCode="jpn" />}
        <div id="scroll-location-section" ref={refTopLocation} />
        <LocationDisplaySection
          locations={locations}
          prefectures={prefectures.data.result}
          locale={locale}
          countryCode={'jpn'}
          onScrollLocation={scrollLocationSection}
        />
        <CostSection />
        {(locale === 'ja' || locale === 'en') && !isSigned && <ReviewSection />}
        {!isSigned && <OwnerSection />}
        {countries.data.result.countries && (
          <CountrySection countries={countries.data.result.countries} locale={locale} />
        )}
      </div>
    </Layout>
  )
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      locale: locale.replace('-', '_').toLowerCase(),
    },
  }
}

export default Top
